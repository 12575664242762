
import { Avatar } from '../api/src/models/Avatar';
import uiMixin from '../mixins/ui';
import UserAvatar from './user-avatar';

export default {
  name: 'UserSettingsAvatar',
  components: {
    UserAvatar,
  },
  mixins: [
    uiMixin,
  ],
  props: {
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      NameNoSelection: Avatar.NameNoSelection,
    };
  },
  computed: {
    hasAvatar() {
      return !!this.$store.getters['userSettings/avatarSelected'];
    },
    items() {
      return [
        ...this.$store.state.userSettings.avatars,
      ];
    },
  },
  methods: {
    async handleSelect(name) {
      if ((!this.hasAvatar && name === this.NameNoSelection) || (this.hasAvatar && this.$store.getters['userSettings/avatarSelected'].name === name)) {
        return;
      }

      const isSelecting = await this.uiMsgBoxConfirm('Are you sure you want to set this as your avatar?');

      if (isSelecting) {
        const result = await this.$store.dispatch('userSettings/selectAvatar', name);

        if (result) {
          this.uiNotify('Avatar updated.', {
            severity: 'success',
          });

          this.onSelect();
        } else {
          this.uiNotify('Unable to update avatar!', {
            severity: 'warning',
          });
        }
      }
    },
  },
};
