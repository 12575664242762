import { render, staticRenderFns } from "./user-settings-avatar.vue?vue&type=template&id=72d71e29&"
import script from "./user-settings-avatar.vue?vue&type=script&lang=js&"
export * from "./user-settings-avatar.vue?vue&type=script&lang=js&"
import style0 from "./user-settings-avatar.vue?vue&type=style&index=0&id=72d71e29&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UserAvatar: require('/opt/build/repo/components/user-avatar.vue').default})
